md-toast.md-bottom.md-right {
  margin-right: 16px; }

md-toast.success .md-toast-content {
  background-color: #087f23;
  color: #fff; }

md-toast.error .md-toast-content {
  background-color: #ba000d;
  color: #fff; }
