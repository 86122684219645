md-menu-content.project-selector {
  width: 320px;
  max-width: 320px;
  max-height: calc(100vh - 10px) !important; }
  md-menu-content.project-selector.md-menu-bar-menu.md-dense .md-button span.highlight {
    font-weight: 700;
    float: none; }
  md-menu-content.project-selector .project-selector-projects {
    overflow: auto; }
  md-menu-content.project-selector md-menu-item .md-button span {
    max-width: calc(100% - 24px);
    overflow: hidden;
    text-overflow: ellipsis; }
  md-menu-content.project-selector md-menu-item.project-selector-not-found {
    height: auto; }
    md-menu-content.project-selector md-menu-item.project-selector-not-found a:hover {
      cursor: pointer; }

.project-selector .md-button.md-icon-button {
  width: 100%; }
  .project-selector .md-button.md-icon-button span {
    max-width: 90%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis; }
  .project-selector .md-button.md-icon-button md-icon {
    display: inline-block;
    vertical-align: top; }

.project-selector-header {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .project-selector-header .hint {
    font-size: 10px; }

.project-selector-action, md-checkbox.include-public {
  margin: 0 4px 0 auto; }

.include-public {
  padding-left: 8px;
  margin-left: 32px;
  margin-bottom: 0; }
  .include-public .md-label {
    margin-left: 8px;
    font-size: 10px; }
