#landing-page {
  min-height: calc( 100vh - 64px - 32px); }
  #landing-page .md-headline {
    margin-top: 0px;
    font-size: 28px;
    font-weight: 500; }
  #landing-page img {
    width: 150px;
    padding: 0;
    margin-top: 8px; }
  #landing-page md-card h3 {
    margin-top: 8px; }
  #landing-page md-card p {
    text-align: center; }
  #landing-page md-card:focus {
    outline: none; }
  @media (max-width: 959px) {
    #landing-page md-card {
      min-width: 250px; } }
