.md-popover {
  background-color: #fff;
  position: absolute; }
  .md-popover-hide {
    display: none; }

.md-popover-button {
  overflow: visible;
  width: auto !important; }
  .md-popover-button > * {
    text-transform: none;
    white-space: normal;
    font-weight: 300; }
